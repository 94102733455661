//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Step3',
  components: {
    IconError: () => import('@tada/icons/dist/IconXCircleS.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    importType: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    nothingFound: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      tableColumns: [
        {
          name: 'index',
          label: '№',
          field: 'index',
          align: 'left',
        },
        { name: 'given_name', label: 'Имя', field: 'given_name', align: 'left' },
        { name: 'phone', label: 'Телефон', field: 'phone', align: 'left' },
        { name: 'role', label: 'Должность', field: 'role', align: 'left' },
        { name: 'section', label: 'Отдел', field: 'section', align: 'left' },
        { name: 'imported', label: '', field: 'imported' },
      ],
    }
  },
  created () {
    console.log(this.value)
  },
  computed: {
    itemSize () {
      const defaultSize = 30
      const contactSize = 43
      const taskSize = 64
      switch (this.importType) {
        case 'contacts':
          return contactSize
        case 'tasks':
          return taskSize
        default:
          return defaultSize
      }
    },
  },
}
