import { render, staticRenderFns } from "./Step3.vue?vue&type=template&id=68b3cce8&scoped=true&"
import script from "./Step3.vue?vue&type=script&lang=js&"
export * from "./Step3.vue?vue&type=script&lang=js&"
import style0 from "./Step3.vue?vue&type=style&index=0&id=68b3cce8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b3cce8",
  null
  
)

export default component.exports
import {QTable,QTr,QTh,QTd,QSpinner} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTr,QTh,QTd,QSpinner})
